import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); // Add first name
  const [lastName, setLastName] = useState(""); // Add last name
  const [error, setError] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = isRegistering
      ? `${process.env.REACT_APP_API_URL}/api/register`
      : `${process.env.REACT_APP_API_URL}/api/login`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          isRegistering
            ? { email, password, firstName, lastName } // Send additional fields during registration
            : { email, password }
        ),
      });
      const data = await response.json();
      if (response.ok) {
        if (isRegistering) {
          alert("Registration successful. Please log in.");
          setIsRegistering(false); // Switch back to login form
        } else {
          localStorage.setItem("token", data.token);
          localStorage.setItem("firstName", data.firstName); // Store firstName in localStorage
          onLoginSuccess();
        }
      } else {
        setError(data.error || "An error occurred");
      }
    } catch (error) {
      setError("An error occurred");
    }
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <button
          onClick={() => (window.location.href = "/")}
          className="text-gray-600 p-2 font-bold"
        >
          <FontAwesomeIcon icon={faHome} className="text-2xl" /> Aquatech
        </button>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/3 mx-auto mt-10 p-5 border rounded">
        <h2 className="text-center text-xl font-semibold mb-4">
          {isRegistering ? "Register" : "Login"}
        </h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSubmit}>
          {isRegistering && (
            <>
              <div className="mb-4">
                <label className="block">First Name</label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block">Last Name</label>
                <input
                  type="text"
                  className="border p-2 w-full"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </>
          )}
          <div className="mb-4">
            <label className="block">Email</label>
            <input
              type="email"
              className="border p-2 w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block">Password</label>
            <input
              type="password"
              className="border p-2 w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white p-2 w-full">
            {isRegistering ? "Register" : "Login"}
          </button>
        </form>
        <div className="text-center mt-4">
          {isRegistering ? (
            <p>
              Already have an account?{" "}
              <button
                className="text-blue-500"
                onClick={() => setIsRegistering(false)}
              >
                Log in here
              </button>
            </p>
          ) : (
            <p>
              Don't have an account?{" "}
              <button
                className="text-blue-500"
                onClick={() => setIsRegistering(true)}
              >
                Register here
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
