import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons"; // Book icon for "Insights"
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="relative min-h-screen flex flex-col justify-center items-center">
      {/* Aquatech SVG Logo */}
      <img
        src={`${process.env.PUBLIC_URL}/favicon.svg`}
        alt="Aquatech Logo"
        className="h-36 mb-6"
      />

      <h1 className="text-4xl font-bold mb-4 mx-4 text-center">
        Welcome to Aquatech
      </h1>
      <p className="text-xl mb-8 mx-4 text-center">
        For an easier aquarium monitoring, please login.
      </p>
      <Link to="/login">
        <button className="bg-blue-500 text-white p-3 rounded">
          Login Here
        </button>
      </Link>

      {/* Aquarium Knowledge Hub Link */}
      <div className="absolute top-5 md:right-5 md:top-5 w-full md:w-auto flex justify-center md:block">
        <Link
          to="/knowledge"
          className="text-gray-700 hover:text-blue-500 flex items-center"
        >
          <FontAwesomeIcon icon={faBook} className="text-3xl mr-2" />
          <span className="text-lg font-semibold">Aquarium Knowledge Hub</span>
        </Link>
      </div>
    </div>
  );
};

export default Home;
