import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTint,
  faVial,
  faFlask,
  faHardHat,
  faCloud,
  faHome,
  faFlaskVial,
  faJugDetergent,
  faAtom,
  faGlassWaterDroplet,
  faTemperatureThreeQuarters,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const parameters = [
  { name: "Temperature", icon: faTemperatureThreeQuarters },
  { name: "Salinity", icon: faFlaskVial },
  { name: "pH", icon: faVial },
  { name: "Ammonia", icon: faFlask },
  { name: "Nitrite", icon: faAtom },
  { name: "Nitrate", icon: faGlassWaterDroplet },
  { name: "Hardness", icon: faHardHat },
  { name: "Phosphate", icon: faJugDetergent },
  { name: "Alkalinity", icon: faTint },
  { name: "Oxygen", icon: faCloud },
];

const Knowledge = () => {
  return (
    <div className="min-h-screen p-8 bg-gray-100">
      <div className="relative flex items-center mb-8">
        <button
          onClick={() => (window.location.href = "/")}
          className="text-gray-600 p-2 font-bold"
        >
          <FontAwesomeIcon icon={faHome} className="text-2xl" />{" "}
          <span className="hidden xl:inline ml-1">Aquatech</span>
        </button>

        {/* Centered Title on larger screens, right aligned on small screens */}
        <h1 className="absolute left-1/2 transform -translate-x-1/2 ml-2 text-lg sm:text-2xl md:text-3xl lg:text-4xl font-bold text-zinc-500 whitespace-nowrap">
          Aquarium Knowledge Hub
        </h1>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {parameters.map((param, index) => (
          <Link
            to={`/knowledge/${param.name.replace(/\s+/g, "-").toLowerCase()}`}
            key={index}
          >
            <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition-transform duration-300">
              <div className="flex justify-center items-center text-blue-500 mb-4">
                <FontAwesomeIcon icon={param.icon} size="3x" />
              </div>
              <h2 className="text-2xl font-bold text-center text-gray-800">
                {param.name}
              </h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Knowledge;
