import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login.js";
import Home from "./pages/Home.js";
import Knowledge from "./pages/Knowledge.js";
import MonitoringData from "./pages/MonitoringData.js";
import ParameterDetails from "./pages/ParameterDetails.js";

function App() {
  const [data, setData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [settings, setSettings] = useState({
    temperature: { minSafe: 20, maxSafe: 28 },
    salinity: { minSafe: 30, maxSafe: 40 },
    alkalinity: { minSafe: 6, maxSafe: 10 },
    nitrate: { minSafe: 5, maxSafe: 12 },
    nitrite: { minSafe: 0, maxSafe: 0.1 },
    phosphate: { minSafe: 0.3, maxSafe: 0.8 },
  });

  // Fetch data without depending on settings to avoid loops
  const fetchData = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found, please log in.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/water`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await response.json();
      if (response.ok) {
        setData(result.data);
        setSettings((prevSettings) => result.settings || prevSettings); // Use functional update to access previous settings
        setIsAuthenticated(true);
      } else {
        console.error("Error fetching data:", result.error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  // Trigger data fetching once when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchData();
    }
  }, [fetchData]);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    fetchData(); // Fetch data after successful login
  };

  const handleLogout = (navigate) => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setData([]);
    setSettings({
      temperature: { minSafe: 20, maxSafe: 28 },
      salinity: { minSafe: 30, maxSafe: 40 },
      alkalinity: { minSafe: 6, maxSafe: 10 },
      nitrate: { minSafe: 5, maxSafe: 12 },
      nitrite: { minSafe: 0, maxSafe: 0.1 },
      phosphate: { minSafe: 0.3, maxSafe: 0.8 },
    });

    // Use the passed navigate function to redirect
    navigate("/login");
  };

  // Save new settings and update state
  const handleSaveSettings = (parameter, newMinSafe, newMaxSafe) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [parameter.toLowerCase()]: {
        minSafe: newMinSafe,
        maxSafe: newMaxSafe,
      },
    }));

    const token = localStorage.getItem("token");
    fetch("http://localhost:5000/api/settings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        [parameter.toLowerCase()]: {
          minSafe: newMinSafe,
          maxSafe: newMaxSafe,
        },
      }),
    }).catch((err) => console.error("Error saving settings:", err));
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/knowledge" element={<Knowledge />} />
        <Route path="/knowledge/:parameter" element={<ParameterDetails />} />
        <Route
          path="/login"
          element={
            !isAuthenticated ? (
              <Login onLoginSuccess={handleLoginSuccess} />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? (
              <MonitoringData
                data={data}
                settings={settings}
                handleSaveSettings={handleSaveSettings}
                handleLogout={handleLogout}
              />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
