import React from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBook } from "@fortawesome/free-solid-svg-icons";
import { parameterData } from "../data/ParameterData.js";

const ParameterDetails = ({ title, content }) => {
  const { parameter } = useParams(); // Get the parameter from the URL
  const data = parameterData[parameter] || {
    title: "Unknown Parameter",
    content: "No information available.",
  };

  return (
    <div className="min-h-screen pb-8 px-8 pt-0 bg-gray-100">
      <div className="sticky top-0 flex justify-between items-start mb-2 bg-gray-100 px-0 py-8 xl:px-8 z-10">
        <button
          onClick={() => (window.location.href = "/")}
          className="text-gray-600  font-bold"
        >
          <FontAwesomeIcon icon={faHome} className="text-2xl" />
          <span className="hidden xl:inline ml-1">Aquatech</span>
        </button>
        <h1 className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold text-zinc-500 text-center">
          {data.title}
        </h1>
        <Link
          to="/knowledge"
          className="text-gray-700 hover:text-blue-500 flex items-center ml-2"
        >
          <FontAwesomeIcon icon={faBook} className="text-3xl mr-2" />
          <span className="text-lg font-semibold">
            <span className="hidden xl:inline">Aquarium Knowledge Hub</span>
          </span>
        </Link>
      </div>

      <div className="bg-white p-8 rounded-lg shadow-lg">
        <p className="text-gray-700 leading-relaxed">{data.content}</p>
      </div>
    </div>
  );
};

export default ParameterDetails;
