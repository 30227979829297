import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faUser, faHome } from "@fortawesome/free-solid-svg-icons";
import ParameterSlider from "../components/ParameterSlider.js";

const MonitoringData = ({
  data,
  settings,
  handleSaveSettings,
  handleLogout,
}) => {
  const navigate = useNavigate(); // Move useNavigate inside the component

  // Pass navigate function to handleLogout so it can redirect
  const logout = () => {
    handleLogout(navigate);
  };

  return (
    <div className="p-5">
      <div className="flex justify-between items-center relative mb-10">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("/")}
            className="text-gray-600 p-2 font-bold"
          >
            <FontAwesomeIcon icon={faHome} className="text-2xl" />{" "}
            <span className="hidden xl:inline ml-1">Aquatech</span>
          </button>
        </div>

        {/* <h1 className="text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold text-zinc-500 text-center">
          {data.title}
        </h1>*/}
        <h1 className="text-xl text-center md:text-3xl 2xl:text-4xl font-bold text-zinc-500 absolute left-1/2 transform -translate-x-1/2">
          Aquarium Monitoring Data
        </h1>

        <div className="flex items-center space-x-2 ml-auto">
          <div className="relative group mr-0 md:mr-7">
            <FontAwesomeIcon
              icon={faUser}
              className="text-gray-600 mb-0 md:mb-2"
            />
            <span className="hidden md:block absolute left-1/2 transform -translate-x-1/2 top-full mt-1 md:-mt-4  text-gray-600 font-semibold whitespace-nowrap group-hover:block">
              {localStorage.getItem("firstName")}
            </span>
          </div>
          <Link
            to="/login"
            onClick={logout} // Use logout function that calls handleLogout with navigate
            className="bg-red-500 text-white p-1 md:p-2 rounded"
          >
            Logout
          </Link>
        </div>
      </div>

      {/* Knowledge Hub Button */}
      <div className="text-center mb-5">
        <Link
          to="/knowledge"
          className="text-gray-700 hover:text-blue-500 flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faBook} className="text-xl mr-2" />
          <span className="text-lg font-semibold">Aquarium Knowledge Hub</span>
        </Link>
      </div>

      {data.length > 0 ? (
        data.map((entry, index) => (
          <div
            className="grid grid-cols-1 md:grid-cols-2 gap-2 justify-items-center mx-auto max-w-screen-lg"
            key={index}
          >
            <ParameterSlider
              parameterName="Temperature"
              value={entry.temperature}
              minSafeValue={settings.temperature.minSafe}
              maxSafeValue={settings.temperature.maxSafe}
              minValue={10}
              maxValue={40}
              unit="°C"
              onSaveSettings={handleSaveSettings}
            />
            <ParameterSlider
              parameterName="Salinity"
              value={entry.salinity}
              minSafeValue={settings.salinity.minSafe}
              maxSafeValue={settings.salinity.maxSafe}
              minValue={20}
              maxValue={50}
              unit="ppt"
              onSaveSettings={handleSaveSettings}
            />
            <ParameterSlider
              parameterName="Alkalinity"
              value={entry.alkalinity}
              minSafeValue={settings.alkalinity.minSafe}
              maxSafeValue={settings.alkalinity.maxSafe}
              minValue={2}
              maxValue={15}
              unit="dKH"
              onSaveSettings={handleSaveSettings}
            />
            <ParameterSlider
              parameterName="Nitrate"
              value={entry.nitrate}
              minSafeValue={settings.nitrate.minSafe}
              maxSafeValue={settings.nitrate.maxSafe}
              minValue={0}
              maxValue={20}
              unit="ppm"
              onSaveSettings={handleSaveSettings}
            />
            <ParameterSlider
              parameterName="Nitrite"
              value={entry.nitrite}
              minSafeValue={settings.nitrite.minSafe}
              maxSafeValue={settings.nitrite.maxSafe}
              minValue={0}
              maxValue={1}
              unit="ppm"
              onSaveSettings={handleSaveSettings}
            />
            <ParameterSlider
              parameterName="Phosphate"
              value={entry.phosphate}
              minSafeValue={settings.phosphate.minSafe}
              maxSafeValue={settings.phosphate.maxSafe}
              minValue={0.1}
              maxValue={1}
              unit="ppm"
              onSaveSettings={handleSaveSettings}
            />
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500 mt-5">No data available</p>
      )}
    </div>
  );
};

export default MonitoringData;
