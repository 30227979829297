import React, { useState } from "react";
import SettingsModal from "./SettingsModal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Assuming you're using FontAwesome
import { faCog } from "@fortawesome/free-solid-svg-icons";

const ParameterSlider = ({
  parameterName,
  value,
  minSafeValue,
  maxSafeValue,
  minValue,
  maxValue,
  unit,
  onSaveSettings, // Function to handle saving settings for the individual parameter
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const valuePosition = ((value - minValue) / (maxValue - minValue)) * 100;
  const isDangerZone = value < minSafeValue || value > maxSafeValue;
  const badgeColor = isDangerZone ? "bg-red-500" : "bg-green-500";
  const borderColor = isDangerZone ? "border-t-red-500" : "border-t-green-500";

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSave = (newMinSafeValue, newMaxSafeValue) => {
    onSaveSettings(parameterName, newMinSafeValue, newMaxSafeValue);
    closeModal();
  };

  return (
    <div className="relative w-3/4 text-center m-10 border-2 rounded-md p-5">
      {/* Parameter Header with Cog */}
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-left text-lg font-semibold text-zinc-500">
          {parameterName}
        </h3>
        {/* Settings Cog */}
        <FontAwesomeIcon
          icon={faCog}
          className="text-gray-500 cursor-pointer"
          onClick={openModal}
        />
      </div>

      {/* Slider and Badge */}
      <div className="relative bottom-1">
        <div
          className="absolute bottom-0 mb-2"
          style={{ left: `${Math.max(10, Math.min(valuePosition, 90))}%` }}
        >
          <div
            className={`${badgeColor} text-white rounded-lg px-2 py-1 transform -translate-x-1/2`}
          >
            {value}
            {unit}
          </div>
          <div className="absolute transform -translate-x-1/2">
            <div
              className={`w-0 h-0 border-l-4 border-r-4 border-t-8 border-transparent ${borderColor}`}
            ></div>
          </div>
        </div>
      </div>

      <div className="relative h-4 bg-gray-200 rounded-full overflow-hidden">
        <div
          className="absolute h-full bg-red-500"
          style={{
            width: `${
              ((minSafeValue - minValue) / (maxValue - minValue)) * 100
            }%`,
          }}
        ></div>
        <div
          className="absolute h-full bg-green-500"
          style={{
            left: `${
              ((minSafeValue - minValue) / (maxValue - minValue)) * 100
            }%`,
            width: `${
              ((maxSafeValue - minSafeValue) / (maxValue - minValue)) * 100
            }%`,
          }}
        ></div>
        <div
          className="absolute h-full bg-red-500"
          style={{
            left: `${
              ((maxSafeValue - minValue) / (maxValue - minValue)) * 100
            }%`,
            width: `${
              ((maxValue - maxSafeValue) / (maxValue - minValue)) * 100
            }%`,
          }}
        ></div>
      </div>

      <div className="flex justify-between text-sm mt-2 text-zinc-500 font-semibold">
        <span>
          {minValue}
          {unit}
        </span>
        <span>
          {maxValue}
          {unit}
        </span>
      </div>

      {/* Modal for Settings */}
      {isModalOpen && (
        <SettingsModal
          parameterName={parameterName}
          minSafeValue={minSafeValue}
          maxSafeValue={maxSafeValue}
          onClose={closeModal}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default ParameterSlider;
