import React, { useState, useEffect } from "react";

const SettingsModal = ({
  parameterName,
  minSafeValue,
  maxSafeValue,
  onClose,
  onSave,
}) => {
  const [minSafe, setMinSafe] = useState(minSafeValue);
  const [maxSafe, setMaxSafe] = useState(maxSafeValue);

  useEffect(() => {
    // Prevent background scroll when modal is open
    document.body.style.overflow = "hidden";
    return () => {
      // Enable scroll when modal is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleSave = () => {
    onSave(minSafe, maxSafe);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full z-50 mx-6">
        <h2 className="text-xl font-bold mb-4 text-zinc-600">
          {parameterName} Settings
        </h2>

        {/* Min Safe Value */}
        <div className="mb-4">
          <label className="block font-semibold text-zinc-600">
            Min Safe {parameterName}
          </label>
          <input
            type="number"
            value={minSafe}
            onChange={(e) => setMinSafe(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>

        {/* Max Safe Value */}
        <div className="mb-4">
          <label className="block font-semibold text-zinc-600">
            Max Safe {parameterName}
          </label>
          <input
            type="number"
            value={maxSafe}
            onChange={(e) => setMaxSafe(Number(e.target.value))}
            className="border p-2 w-full"
          />
        </div>

        {/* Save and Cancel Buttons */}
        <div className="text-right">
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white p-2 rounded mr-2"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white p-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
